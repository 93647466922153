import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';

import backgroundDesktop from '../../images/bg-calculator.svg';
import backgroundMobile from '../../images/rectangle-copy-3.svg';
import arrowRightIcon from '../../images/arrow1.svg';
import arrowLeftIcon from '../../images/arrow2.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';

const cards = [
  {
    title: 'Depression is the #1 cause of disability worldwide',
    description: 'Do we have to wait for it to get worse to take action?',
  },
  {
    title: 'EAP utilization  is less than 3%',
    description: 'Yet most won’t report their revenue',
  },
  {
    title: 'There’s a shortage of therapists',
    description: 'In California there are 20 therapists per 10k residents',
  },
  {
    title: 'Over 60% of therapists don’t take insurance',
    description: 'Because insurance companies only pay therapists $60 per visit ',
  },
];

const facts = [
  'Over the next two decades, the cost of mental illness in the workplace will be more than cancer, diabetes, and respiratory ailments combined.',
  '70% of employees say their jobs are very stressful and are considering or actively looking for a new job.',
  'For every $1 employers spend on mental health, they save $6 in combined medical and absenteeism costs.',
];

const sliderSettings = {
  initialSlide: 0,
  loop: true,
  centeredSlides: true,
  speed: 400,
  simulateTouch: true,

  grabCursor: true,
  setWrapperSize: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
    delay: 5000,
  },
};

const BackgroundMask = styled.div`
  background-color: ${color.aliceBlueLight};
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 5%;
`;

const SectionWrapper = styled(Col)`
  position: relative;
  z-index: 5;
  padding: 5rem 1rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  ${mediaQueries.sm} {
    padding: 3rem 1rem;
  }
`;

const ContentRow = styled(Row)`
  margin: 0 auto;
  width: 90vw;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div`
  margin: 0 auto;
  ${mediaQueries.md} {
    margin: 0;
  }
  .swiper-container {
    max-width: 90rem;
  }
  .swiper-slide {
    padding: 1.5rem 10rem;
    ${mediaQueries.md} {
      padding: 1.5rem 0rem;
    }
  }
  .swiper-pagination-bullet-active {
    background: white;
  }
  .swiper-button-next {
  background-image: url('${arrowRightIcon}');
  ${mediaQueries.md} {
      display: none;
    }
  }
  .swiper-button-prev {
  background-image: url('${arrowLeftIcon}');
  ${mediaQueries.md} {
      display: none;
    }
  }
`;

const CardsWrapper = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  ${mediaQueries.xxxl} {
    padding: 3rem 5rem 0rem;
  }
  ${mediaQueries.xxl} {
    padding: 3rem 0;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const CardWrapper = styled(Col)`
  margin: 1rem;
  margin-bottom: 20px;
  display: block;
  ${mediaQueries.xl} {
    margin: 2rem;
    margin-bottom: 20px;

  }
  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 20px;

  }
`;

const CardHeading = styled.h3`
  font-size: 24px;
  color: ${color.mariner};
  font-weight: 700;
`;

const CardDescription = styled.p`
  font-size: 16px;
  color: ${color.manatee};
`;

const CardContainer = styled.div`
  padding: 3rem 2rem;
  max-width: 288px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  background-color: ${color.white};
  ${mediaQueries.lg} {
    width: 288px;
    height: 300px;
  }
`;

const SectionBackgroundIcon = styled.i(({ desktopIcon, mobileIcon }) => ({
  zIndex: 2,
  position: 'absolute',
  backgroundImage: `url('${desktopIcon}')`,
  width: '100%',
  height: '50rem',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  [mediaQueries.lg]: {
    height: '59rem',
  },
  [mediaQueries.md]: {
    backgroundImage: `url('${mobileIcon}')`,
    height: '40rem',
  },
  [mediaQueries.sm]: {
    backgroundImage: `url('${mobileIcon}')`,
    height: '25rem',
  },
}));

const FactsSection = () => (
  <>
    <BackgroundMask />
    <SectionBackgroundIcon desktopIcon={backgroundDesktop} mobileIcon={backgroundMobile} />
    <SectionWrapper>
      <ContentRow>
        <HeaderWrapper>
          <S.SectionHeading css={{ color: color.white }}>
            It’s time to do something about our mental well-being
          </S.SectionHeading>
          <Swiper {...sliderSettings}>
            {facts.map(fact => (
              <S.SectionSwipeHeading key={fact}>{fact}</S.SectionSwipeHeading>
            ))}
          </Swiper>
          <CardsWrapper type="flex">
            {cards.map(card => (
              <CardWrapper key={card.title} md={20} lg={7} xl={5}>
                <CardContainer>
                  <CardHeading>{card.title}</CardHeading>
                  <CardDescription>{card.description}</CardDescription>
                </CardContainer>
              </CardWrapper>
            ))}
          </CardsWrapper>
        </HeaderWrapper>
      </ContentRow>
    </SectionWrapper>
  </>
);

export default FactsSection;
