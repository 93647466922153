import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import leftCloudIcon from '../../images/shape-half-cloud-outline.svg';
import rightCloudIcon from '../../images/shape-half-cloud.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';

const SectionWrapper = styled(Col)`
  padding: 0 0 7rem;
  width: 100%;
  background-color: ${color.white};
  background-size: cover;
  padding-top: 200px;
  ${mediaQueries.md} {
    padding: 2rem 0;
  }
  ${mediaQueries.sm} {
    margin-top: 0px;
    padding: 1rem 1rem;
    padding-top: 100px;

  }
`;

const ContentRow = styled(Row)`
  justify-content: center;
  width: 100%;
  padding: 2rem 5rem;
  display: flex;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div`
  margin: 0 10rem;
  ${mediaQueries.md} {
    margin: 0 5rem;
  }
  ${mediaQueries.sm} {
    margin: 0 1rem;
  }
`;

const SectionDescription = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: ${color.bermudaGrey};
  line-height: 1.8;
  opacity: 0.8;
  max-width: 50rem;
  margin: 0 auto;
  ${mediaQueries.md} {
    font-size: 16px;
  }
`;

const SectionIcon = styled.i(({ iconUrl, leftIcon }) => ({
  display: 'block',
  position: 'absolute',
  backgroundImage: `url('${iconUrl}')`,
  width: '8.9rem',
  height: '8rem',
  right: leftIcon ? 'auto' : 0,
  backgroundRepeat: 'no-repeat',
  [mediaQueries.sm]: {
    display: 'none',
  },
}));

const WhatDoYouGetSection = () => (
  <SectionWrapper>
    <SectionIcon iconUrl={rightCloudIcon} />
    <SectionIcon leftIcon iconUrl={leftCloudIcon} />
    <ContentRow>
      <HeaderWrapper>
        <S.SectionHeading>How we do it</S.SectionHeading>
        <SectionDescription>
        Our platform uses research-backed assessments and a proprietary blend of technology and personal support to triage users to an appropriate level of care. By giving your employees better access to support, your business will benefit from more productive employees, less turnover, and a best-in-class culture        </SectionDescription>
      </HeaderWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default WhatDoYouGetSection;
