import React from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';

import forBusinessesIcon from '../../images/ludki.svg';
import { mediaQueries, color } from '../variables';
import RequestDemoButtonWidget from '../shared/components/RequestDemoButtonWidget';
import RequestWhitepaperButtonWidget from '../shared/components/RequestWhitepaperButtonWidget';
import * as S from '../shared/components/styles';

const SectionWrapper = styled(Col)`
  padding: 15rem 9rem 0;
  background-color: ${color.aliceBlueLight};
  &:last-of-type {
    padding-bottom: 4rem;
  }
  ${mediaQueries.xl} {
    padding: 10rem 5rem 0;
  }
  ${mediaQueries.lg} {
    padding: 10rem 2rem 0;
  }
  ${mediaQueries.sm} {
    padding: 7rem 1rem 0;
  }
  background-size: cover;
`;

const ContentRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
`;

const ContentColumn = styled(Col)`
  text-align: center;
  padding: 0 1rem;
  ${mediaQueries.sm} {
    padding: 0;
  }
`;

const SectionIcon = styled.img({
  zIndex: 2,
  display: 'flex',
  position: 'relative',
  width: '33rem',
  height: '19rem',
  backgroundRepeat: 'no-repeat',
  [mediaQueries.xl]: {
    width: '33rem',
    height: '15rem',
  },
  [mediaQueries.sm]: {
    width: '16rem',
    height: '9rem',
  },
});

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

const ButtonsWrapper = styled.div`
  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
  }
  ${mediaQueries.sm} {
    display: flex;
    flex-direction: column;
  }
`;

const HappyEmployeesSection = () => (
  <>
    <SectionWrapper>
      <ContentRow>
        <ContentColumn>
          <S.SectionWelcomeHeading>Mental Well-being Solutions for Your People</S.SectionWelcomeHeading>
          <S.SectionDescription
            semiTranspatentOpacity
            css={{ maxWidth: '49rem', margin: '2rem auto' }}
          >
           We provide companies a platform to engage their whole team with mental well-being resources, regardless of what each employee needs.
          </S.SectionDescription>
          <ButtonsWrapper>
            <RequestDemoButtonWidget />
          </ButtonsWrapper>
        </ContentColumn>
      </ContentRow>
      <IconWrapper>
        <SectionIcon src={forBusinessesIcon} />
      </IconWrapper>
    </SectionWrapper>
  </>
);

export default HappyEmployeesSection;
