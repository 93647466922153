import React from 'react';
import styled from '@emotion/styled';

import cultureOfSupportIcon from '../../images/ill_3.svg';
import procativeSolutionsIcon from '../../images/ill_2.svg';
import accessAndTriageIcon from '../../images/ill_1.svg';
import * as S from '../shared/components/styles';
import { mediaQueries, color } from '../variables';

const cards = [
    {
        title: 'Digital Resources',
        paragraph: 'Our digital courses, meditations, and resources enable employees to take a proactive approach to their mental well-being.',
        image: accessAndTriageIcon
    },
    {
        title: 'Certified Coaches',
        paragraph: 'Our network of coaches can help employees manage stress related to work, personal relationships, or other life challenges.',
        image: cultureOfSupportIcon
    },
    {
        title: 'Licensed Therapy',
        paragraph: 'We make finding and seeing a therapists easy for your employees who have access to our global network of licensed therapists.',
        image: procativeSolutionsIcon
    },
]

export const SectionWrapper = styled.div`
  background-color: ${color.white};
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5rem
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  background-color: ${color.white};
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
  ${mediaQueries.sm} {
      width: 80%;
}
`;

export const SectionIcon = styled.img(({ centered, extraMargin }) => ({
    marginLeft: extraMargin && '2rem',
    display: 'block',
    position: 'relative',
    width: '10rem',
    height: '10rem',
    backgroundRepeat: 'no-repeat',
}));



const HowWeDoIt = () => (
    <SectionWrapper>
        {cards.map((card, i) => {
            return (
                <Card key={'howwedoit' + i}>
                    <SectionIcon src={card.image} />
                    <S.CardHeading style={{marginTop: 5, marginBottom: 0, paddingBottom: 0, fontSize: 30}}>{card.title}</S.CardHeading>
                    <S.SectionDescription small style={{ marginTop: 0, textAlign: 'center', fontSize: 18, width: '80%'}}>
                        {card.paragraph}
                    </S.SectionDescription>
                </Card>
            )
        })}
    </SectionWrapper>
);

export default HowWeDoIt;
