import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import HappyEmployeesSection from '../components/businesses/HappyEmployeesSection';
import FactsSection from '../components/businesses/FactsSection';
import WhatDoYouGetSection from '../components/businesses/WhatDoYouGetSection';
import HowWeDoIt from '../components/businesses/HowWeDoIt';

import WantToTakeCareSection from '../components/index/WantToTakeCareSection';
import FooterWrapper from '../components/index/FooterWrapper';

const ForBusinessesPage = () => (
  <Layout>
    <Navigation />
    <HappyEmployeesSection />
    <FactsSection />
    <WhatDoYouGetSection />
    <HowWeDoIt/>
    <FooterWrapper children={<WantToTakeCareSection />} />
  </Layout>
);

export default ForBusinessesPage;
